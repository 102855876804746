/*jshint esversion: 6 */
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	iFrameResize({ log: true }, '#ct-iframe');

	var isOldIE = (navigator.userAgent.indexOf("MSIE") !== -1); // Detect IE10 and below

	iFrameResize({
		heightCalculationMethod: isOldIE ? 'max' : 'lowestElement'
	});

	//Click event to scroll to top
	$('body').on('click', '.go-top', function () {
		TweenMax.to(window, 0.8, { scrollTo: { y: 0 }, ease: Quad.easeOut });
	});

	$('input#location').keypress(function (e) {
		if (e.which === 13) {
			$('form#locationfinder').submit();
		}
	});

	// navigation hover backdrop
	$('header .nav-container nav > ul > li.menu-item-has-children').hover(function () {
		$('body').addClass('dropdown-active');
	}, function () {
		$('body').removeClass('dropdown-active');
	});


	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages
				$('select').not('#lang_choice_1, #location-link').selectpicker();
				$('.navbar-nav > li > a').on('click', function (e) {
					if (!$(this).parent('li').hasClass('menu-item-has-children')) {
						return;
					}
					if ($(window).width() > 768 && $(window).width() < 1200) {
						var li = $(this).parent('li');
						$('> ul', li).css('display', 'block');
						e.preventDefault();

					}
				});
				// Sidebar Navigation Handling
				$('.nav-sidebar .js-caret-sidebar').on('click', function (e) {
					e.preventDefault();
					var li = $(this).closest('li');
					li.find('.dropdown-menu').slideToggle(200);
					li.toggleClass('acc--open');
				});

				var activeEle = $('.nav-sidebar li.active');

				if (activeEle.hasClass('dropdown')) {
					jQuery('.dropdown-menu', activeEle).css('display', 'block');
				}
				else {
					activeEle.parents('ul').css('display', 'block');
					activeEle.parents('li').addClass('acc--open');
				}


				
				var iteration = 0;
				var maxIterations = 40;
				var scrollHtml = '<div class="ct-scroll-navigation"><button data-scroll-left class="init-hidden"><</button><button data-scroll-right>></button></div>';
				var scrollPosition = 0;
				var interaval = setInterval(() => {
					count();
				}, 500);

				function tableChange() {
					if($('.dataTables_wrapper').length > 0 || iteration == maxIterations){
						clearInterval(interaval);
					}else{
						return;
					}

					$('.dataTables_wrapper').each(function(){
						if($(this).find('.tablepress').width() > $(this).width()){
							$(this).before(scrollHtml);
						}
					});
				}

				function count() {
					iteration++;
					tableChange();
				}

				function tableScrollLeft(element, width = 0){
					if(width == 0){
						scrollPosition -= 300;
					}else{
						scrollPosition -= width;
					}

					if(scrollPosition < 0){
						scrollPosition = 0;
					}
					
					$(element).animate({scrollLeft: scrollPosition}, 250);
				}
				function tableScrollRight(element, width = 0){
					if(width == 0){
						scrollPosition += 300;
					}else{
						scrollPosition += width;
					}
					if(scrollPosition > ($(element.children()[0]).width() - element.width())){
						scrollPosition = $(element.children()[0]).width() - element.width();
					}

					$(element).animate({scrollLeft: scrollPosition}, 250);
				}

				

				$('body').on('click', '[data-scroll-right]', function(){
					let parent = $(this).parent().next('.dataTables_wrapper');
					tableScrollRight(parent, parent.width() - 300);
					$('[data-scroll-left].init-hidden').removeClass('init-hidden');
				});
				$('body').on('click', '[data-scroll-left]', function(){
					let parent = $(this).parent().next('.dataTables_wrapper');
					tableScrollLeft(parent, parent.width() - 300);
				});
				
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(window).load(function () {

	var hyphenActive = false;

	function updateHyphen() {

		jQuery('h1, .sidebar-menu-wrapper a, .home-header__content h2, .sidebar-menu-wrapper h3, .sidebar-item a, .sidebar-item h3, .sidebar-item p').hyphenate('de');
		hyphenActive = true;

	}



	if (jQuery(window).width() <= 991) {
		updateHyphen();
	}

	jQuery(window).on('resize', function () {
		if (jQuery(window).width() <= 991 && hyphenActive === false) {
			updateHyphen();
		}
	});

});

// set news boxes to same height
function adjustHeightTopNews() {
	//titles
	var heightsTitle = jQuery(".top-news .news-box h3").map(function () {
		return jQuery(this).innerHeight();
	}).get();
	var maxHeightTitle = Math.max.apply(null, heightsTitle);
	jQuery(".top-news .news-box h3").css('height', maxHeightTitle + 'px');

	//images
	var heights = jQuery(".top-news .news-image").map(function () {
		return jQuery(this).height();
	}).get();
	var maxHeight = Math.max.apply(null, heights);
	jQuery(".top-news .news-image").css('height', maxHeight + 'px');

}

function adjustHeightRegularNews() {
	//titles
	var heightsTitle = jQuery(".regular-news .news-box h3").map(function () {
		return jQuery(this).innerHeight();
	}).get();
	var maxHeightTitle = Math.max.apply(null, heightsTitle);
	jQuery(".regular-news .news-box h3").css('height', maxHeightTitle + 'px');

	//images
	var heights = jQuery(".regular-news .flex-content").map(function () {
		return jQuery(this).height();
	}).get();
	var maxHeight = Math.max.apply(null, heights);
	jQuery(".regular-news .flex-content").css('height', maxHeight + 'px');
}


// set news boxes to same height if device is not mobile
if (jQuery(window).width() > 767) {
	adjustHeightTopNews();
	adjustHeightRegularNews();
}

(function ($) {
	$('[data-flexible-posts]').each(function () {
		var $grid = $(this).find('[data-posts]').isotope({
			itemSelector: '.sortable',
			hiddenStyle: {
				opacity: 0
			},
			visibleStyle: {
				opacity: 1
			}
		});
		$(this).find('[data-select]').on('change', function () {
			if (this.value !== 'all') {
				$(this).attr('data-select', '.' + this.value);
			} else {
				$(this).attr('data-select', '');
			}
			var searchTerms = [];
			$(this).parents('[data-flexible-posts]').find('[data-select]').each(function () {
				var term = $(this).attr('data-select');
				if(term.length > 0){
					searchTerms.push(term);
				}
			});
			if(searchTerms.length > 0){
				console.log(searchTerms);
				$grid.isotope({ filter: searchTerms.join('') });
			} else {
				console.log(searchTerms);
				$grid.isotope({ filter: '*' });
			}

		});
	});
})(jQuery);