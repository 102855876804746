jQuery(document).ready(function( $ )
{

    function transform( ele, value ) {
        if (value === '0%') {
            ele.css({
                '-webkit-transform' : 'none',
                '-moz-transform'    : 'none',
                '-ms-transform'     : 'none',
                '-o-transform'      : 'none',
                'transform'         : 'none'
            });

            if (! $('body').hasClass('search-active')) {
                $('.navbar-nav').attr('style', 'height: auto');
            }
        } else {
            ele.css({
                '-webkit-transform' : 'translate(' + value + ')',
                '-moz-transform'    : 'translate(' + value + ')',
                '-ms-transform'     : 'translate(' + value + ')',
                '-o-transform'      : 'translate(' + value + ')',
                'transform'         : 'translate(' + value + ')'
            });
        }
    }

    /*
     * Responsive Nav
     */

    var defaultOpts = {
        maxWindowWidth : '769',
        parentLinkClickable : true
    };

    var isClickHandlerBound = false;

    var opts = defaultOpts;

    var mainMenu = $('.navbar-nav');

    var currentMenuDepth = 0;

    var hasSubMenuItems = $('li.menu-item-has-children > a', mainMenu);

    var subMenuClickHandler = function ( e ) {
        e.preventDefault();

        // Save Element in Variable to append to menu
        var parentMenuItem = jQuery(this).parent();
        var parentMenuLink = jQuery(this);
        var subMenu = jQuery('> ul', parentMenuItem);

        if( opts.parentLinkClickable ) {
            if ( ! subMenu.find('.prev-clickable').length) {
                subMenu.prepend( '<li class="menu-item prev-clickable"><a href="' + parentMenuLink.attr('href') + '"><span>' + parentMenuLink.text() + '</span></a></li>' );
            }

            subMenu.prepend( '<li class="parent-link btn-back">' + parentMenuLink.get(0).outerHTML + '</li>' );

        }

        parentMenuItem.addClass('drop-active');

        // Increase Menu Depth
        currentMenuDepth++;

        var menuTranslation = currentMenuDepth * 100;

        var subMenuOuterHeight = subMenu.outerHeight();

        $('.nav-container, .navbar-collapse').attr('style', 'height: ' + subMenuOuterHeight + 'px !important');

        transform( mainMenu, -menuTranslation + '%' );
    };


    mainMenu.on('click', '.btn-back', function() {
        if($(this).parent().parent().parent().hasClass('nav')){
            $('.search-open').show();
        }
        $(this).parent('ul').parent('li').removeClass('drop-active');
        $(this).siblings('.parent-link').remove();
        $(this).remove();

        var subMenu = $('.navbar-nav').find('.drop-active:first').find('.sub-menu:first');
        var subMenuOuterHeight = subMenu.outerHeight();

        $('.nav-container, .navbar-collapse').attr('style', 'height: ' + subMenuOuterHeight + 'px !important');

        currentMenuDepth--;

        var menuTranslation = currentMenuDepth * 100;

        transform( mainMenu, -menuTranslation + '%' );

        return false;
    });

    $(window).on('load resize', function(){
        if ($(window).width() > opts.maxWindowWidth){
            // Remove all parent links
            $( '.parent-link, .prev-clickable, .btn-back, .drop-active' ).remove();
            $('.nav-container, .navbar-collapse').attr('style', '');
            if( isClickHandlerBound ) {
                hasSubMenuItems.unbind('click', subMenuClickHandler);
            }
            currentMenuDepth = 0;
            transform( mainMenu, '0%' );
        } else {
            if( !isClickHandlerBound ) {
                hasSubMenuItems.bind( 'click', subMenuClickHandler );
                isClickHandlerBound = true;
            }
        }
    });

    $('.navbar-collapse').on('hidden.bs.collapse', function () {
        jQuery('body').removeClass('no-scroll');
        $('.search-language-container').removeClass('visible');
    });

    $('.navbar-collapse').on('shown.bs.collapse', function () {
        jQuery('body').addClass('no-scroll');
        $('.search-language-container').addClass('visible');
    });
});