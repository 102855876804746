(function ($) {
	var isCalculating = false;

	function isAbove(element, fixedElement) {
		return (element.offset().top < fixedElement.offset().top + fixedElement.outerHeight() && element.offset().top + element.outerHeight() > fixedElement.offset().top);
	}

	function isPast(element, fixedElement) {
		return (element.offset().top + element.outerHeight() < fixedElement.offset().top);
	}

	function isOnElementContainer() {
		var fixedElement = $('[data-fixed-element]');
		var container = $('.timeline');
		if (isAbove(container, fixedElement)) {
			return true;
		}
		return false;
	}

	function checkHistoryElements() {
		var fixedElement = $('[data-fixed-element]');
		$('[data-timeline-entry]').each(function () {
			if (isAbove($(this), fixedElement)) {
				$(this).removeClass('faded');
				$(this).removeClass('coming-up');
			} else {
				if (isPast($(this), fixedElement)) {
					$(this).removeClass('coming-up');
					$(this).addClass('faded');
				} else {
					$(this).addClass('coming-up');
					$(this).removeClass('faded');

				}
			}
		});
	}

	function checkYearElements() {
		setTimeout(function() {
			var fixedElement = $('[data-fixed-element]');
			$('[data-timeline-year]').each(function () {
				if (isCalculating) {
					if (isAbove($(this), fixedElement)) {
						fixedElement.removeClass('is-visible');
						isCalculating = false;
						return;
					} else {
						if (isOnElementContainer()) {
							fixedElement.addClass('is-visible');
						} else {
							fixedElement.removeClass('is-visible');
						}
					}
				}
			});
			isCalculating = false;
		}, 200);
	}


	$(document).ready(function () {

		$('[data-history-slider]').slick({
			lazyLoad: 'ondemand',
			dots: true,
		});


		$(window).on('scroll', function () {
			isCalculating = true;
			checkHistoryElements();
			checkYearElements();
		});




	});

})(jQuery);